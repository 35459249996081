import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
	blogHeader,
	blogPostContainer,
	postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox, videoBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";

const BlogPage = () => {
	return (
		<LayoutService>
			<Helmet>
				<title>
					How Much Should I Tip a Boat Captain? | Tampa Bay Boating Adventures
				</title>
				<meta
					name="description"
					content="Do I need to tip a boat captain in Tampa? Tipping your aquatic crew is no different than any other service industry. What's considered standard? Learn more about the appropriate amount to tip your crew in our blog post."
				/>
				<meta name="keywords" content="Tips" />
			</Helmet>
			<main className="container">
				<div className="row">
					<div className="col-xxs-12">
						<div className={blogHeader}>
							<div className={imgBox}>
								<StaticImage
									alt="captain of a boat cruise tip from customers"
									src="../images/blog/captain-of-boat-cruise-tip-from-customers.jpg"
								/>
							</div>
						</div>
						<div className={blogPostContainer}>
							<div className="row center">
								<div className="col">
									<h1>How Much Should I Tip a Boat Captain? </h1>
									<p>November 02, 2023</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xxs-12">
									<div className={postContentBlock}>
										<h3>
											Tipping is standard in any service industry, including
											charter boat services in Tampa Bay.
										</h3>
										<p>
											Boat captains are absolutely vital for you to enjoy a
											great trip out on the water. Whether you are sailing,
											fishing or simply cruising with a few friends, it is
											important to remember that your charter boat captain and
											crew are essential to providing you with a service – just
											like all other workers in the service industry.{" "}
											<strong>
												If you were out for a meal, you would tip the staff that
												helped you to show your appreciation for them, and being
												guided on a boat is no different.
											</strong>{" "}
											Remember, boat crews are there for both your safety and
											your satisfaction.
										</p>
										<p>
											To help you out, we have created this quick guide on how
											much you should tip a boat captain, taking into
											consideration their unique role and expertise. Let’s take
											a closer look:
										</p>
										<div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="captained boat cruise tampa bay"
													src="../../images/banners/captained-boat-cruise-tampa-bay.jpg"
												/>
											</div>
										</div>
										<h2>Your Captain Does More Than Drive the Boat</h2>
										<h3>
											Understanding Tampa’s local waterways and being trained in
											live-saving first aid are requirements.
										</h3>
										<p>
											Your boat crew are there to make your boat cruise as
											comfortable as possible, offering excellent boat skills
											and great customer service. On top of this, however,{" "}
											<strong>
												they are also trained to help save your life in an
												emergency.
											</strong>{" "}
											If the worst does happen, they are specially trained to
											safely navigate your vessel or provide assistance if you
											need it. Because of this, it is important to show that you
											value them, as much as they value you and a tip is a great
											way to do this.
										</p>
										<hr />
										<div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="charter boat with logo downtown tampa"
													src="../../images/banners/charter-boat-with-logo-downtown-tampa.jpg"
												/>
											</div>
										</div>
										<h2>Tipping is Standard in the Charter Boat Industry</h2>
										<h3>
											Your tipping rate should reflect your charter boat
											experience and match the service industry standard.
										</h3>
										<p>
											Tipping rates for boat captains and for other boat crew
											members are similar to others in the service industry –{" "}
											<strong>
												around 15 - 20% of the total cost of your boat charter.
											</strong>{" "}
											Like other industries, however, feel free to increase the
											tip amount if you feel like you have had excellent
											service, or the staff have gone above and beyond for your
											comfort. Tip at the end of the charter as you depart the
											boat or through the company’s automated booking software.
										</p>
										<hr />
										<h2>
											Tipping the Captain & Crew for Exemplary Performance
										</h2>
										<h3>
											If another member of the crew went above-and-beyond, feel
											free to tip them separately.
										</h3>
										<p>
											However, the boat captain is not usually the only staff
											member on the boat – particularly if you are on a party
											cruise. Boat charters come with many different types of
											staff from DJs to bottle girls. These staff members also
											deserve a tip for making your journey enjoyable,
											recognising each of their efforts separately.
										</p>
										<p>
											Instead of simply tipping the staff in one tip,{" "}
											<strong>
												it is recommended that you split your tips up and give
												them to the captain and crew members separately in order
												to make the tipping process a little more personal and
												to make the staff feel valued.
											</strong>
										</p>
										<p>
											This helps foster a great relationship between you and the
											crew, and if you use the same company again, you are
											likely to see them going above and beyond to continue
											giving you a great experience.
										</p>
										<hr />
										<h2>
											Providing a Five-Star Charter Boat Experience is Worthy of
											Tipping
										</h2>
										<h3>
											If you made lasting memories on your party boat, consider
											tipping 15 - 20% to show appreciation.
										</h3>
										<p>
											To summarize, tipping is normal for both the boat captain
											and boat crew.{" "}
											<strong>
												The usual amount to tip is at least 15%, but more is
												also happily accepted if you are delighted with your
												boating experience.
											</strong>{" "}
											Boat staff are at your service while on the charter and
											provide much more than just driving the boat. The crew can
											show you aspects of Tampa Bay that you have never seen and
											can even save your life in an emergency. Adding that
											little extra appreciation goes a long way.
										</p>
										<hr />
										<h2>Select Your Party Boat Charter</h2>
										<h3>
											See what all's included with our wide variety of party
											boat charter packages.
										</h3>
									</div>
								</div>
							</div>
						</div>
						<ServicesList />
					</div>
				</div>
			</main>
		</LayoutService>
	);
};

export default BlogPage;
